<template>
  <svg
    width="118"
    height="118"
    viewBox="0 0 118 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M93.9278 0H23.1013C10.3428 0 0 10.3428 0 23.1013V93.9278C0 106.686 10.3428 117.029 23.1013 117.029H93.9278C106.686 117.029 117.029 106.686 117.029 93.9278V23.1013C117.029 10.3428 106.686 0 93.9278 0Z"
      fill="#DAF6FF" />
    <path
      d="M80.1721 35.7545H59.7484L55.7057 28.2466H20.8963V38.7472H49.4054L53.4481 46.2551H82.9547C83.9523 46.2551 84.7923 47.0952 84.7923 48.0927V76.4968C84.7923 77.4944 83.9523 78.3344 82.9547 78.3344H33.2344C32.2369 78.3344 31.3968 77.4944 31.3968 76.4968V50.5604H20.8963V73.6616C20.8963 82.0096 27.6691 88.835 36.0171 88.835H80.1721C88.5201 88.835 95.2929 82.0621 95.2929 73.6616V50.8754C95.2929 42.5274 88.5201 35.702 80.1721 35.702V35.7545Z"
      fill="#015965" />
  </svg>
</template>
